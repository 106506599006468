@font-face {
	font-family: "Gotham";
	src: url("../assets/fonts/Gotham/Gotham-Medium.otf") format("opentype"),
}
@font-face {
  font-family: "Gotham";
  font-weight: bold;
	src: url("../assets/fonts/Gotham/Gotham-Bold.otf") format("opentype"),
}
@font-face {
	font-family: "Gotham";
	font-weight: 350;
	src: url("../assets/fonts/Gotham/Gotham-Book.otf") format("opentype"),
}
