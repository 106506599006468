* {
  box-sizing: border-box;
}

body {
  margin: 0;
  padding: 0;
  font-family: 'Gotham', Helvetica, sans-serif;
  overflow-x: hidden;
}

::-webkit-scrollbar {
  width: 10px;
}

/* Track */
::-webkit-scrollbar-track {
  background: #252425;
}

/* Handle */
::-webkit-scrollbar-thumb {
  background: #888;
  border-radius: 10px;
}

/* Handle on hover */
::-webkit-scrollbar-thumb:hover {
  background: #555;
}

.rgpd__modal__wrapper {
  position: fixed;
  top: 0;
  width: 100%;
  height: 100vh;
  background-color: rgba($color: #000, $alpha: .7);;
  display: flex;
  justify-content: center;
  align-items: center;
  z-index: 99999;

  .rgpd__modal {
    width: 80%;
    height: auto;
    background-color: rgba($color: #242324, $alpha: 1.0);
    box-shadow: 11px 9px 19px;
    padding: 20px;
    display: flex;
    flex-direction: column;
    align-items: center;

    .rgpd__modal__title {
      color: #5ed7fd;
      font-weight: 300;
      margin: 0;
      text-align: center;
      font-size: 20px;
    }

    .rgpd__modal__text {
      color: #FFF;
      text-align: center;
      display: flex;
      flex-direction: column;
      font-weight: 300;
      font-size: 16px;

      span {
        margin: 10px 0;
      }
    }

    .rgpd__consent__button {
      background-color: transparent;
      color: #5ed7fd;
      border: 4px solid #5ed7fd;
      border-radius: 0;
      width: 150px;
      height: 55px;
      font-size: 16px;
      cursor: pointer;
    }
  }
}

@media screen and (min-width: 1024px) {
  .rgpd__modal__wrapper {
    .rgpd__modal {
      width: 55%;
      min-height: 300px;
      height: auto;
      padding: 30px;

      .rgpd__modal__title {
        font-size: 26px;
      }
    }
  }
}
