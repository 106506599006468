.lto__header {
  position: relative;

  nav {
    width: 92%;
    height: 10vh;
    display: flex;
    justify-content: space-between;
    align-items: center;

    .logoContainer {
      height: 100%;
      display: flex;
      margin-left: 30px;
      justify-content: flex-start;
      align-items: center;
    }

    .lto__menu {
      display: none;
      justify-content: flex-end;
      align-items: center;

      li {
        list-style: none;
        margin: 0 25px;

        a {
          color: $white;
          font-weight: bold;
          text-decoration: none;
          font-size: 20px;

          &:visited {
            color: $white;
          }
        }

        &:last-child {
          margin-right: 0;
        }
      }
    }

    &.lto__nav--mobile  {
      position: fixed;
      width: 100%;
      height: 100vh;
      right: -100%;
      top: 0;
      background: linear-gradient(180deg, rgba($background-color-lighter,1) 0%, rgba($background-color-darker,1) 100%);
      z-index: 999;
      display: flex;
      flex-direction: column;
      justify-content: space-evenly;
      transition: transform 0.5s ease;

      &.opened {
        transform: translateX(-100%);
        transition: transform 0.5s ease;
      }

      .logoContainer--mobile {
        width: 100%;
        height: 50%;
        display: flex;
        justify-content: center;
        align-items: center;
        padding: 0;
        margin: 0;

        .logo__menu--mobile {
          width: 100%;
          height: auto;
        }
      }

      .lto__menu--mobile {
        width: 100%;
        padding: 0;
        margin: 0;

        li {
          background: linear-gradient(180deg, rgba($background-color-lighter,1) 0%, rgba($background-color-darker,1) 100%);
          list-style: none;
          color: $white;

          a {
            width: 100%;
            height:60px;
            display: flex;
            align-items: center;
            padding: 0 30px;
            text-decoration: none;
            font-size: 24px;
            img {
              height: 18px;
              width: auto;
            }

            &:hover, &:visited {
              color: $white;
              text-decoration: none;
              font-size: 24px;
            }
          }
        }
      }
    }

    .lto__mobile__menu__button {
      width: 35px;
      height: 30px;
      display: flex;
      justify-content: center;
      align-items: center;
      position: absolute;
      right: 30px;
      z-index: 9999;
      border: none;
      background-color: transparent;
      padding: 0;

      &:focus {
        outline: none;
      }

      .lto__mobile__menu {
        width: 35px;
        height: 2px;
        border-radius: 2px;
        background-color: $white;
        z-index: 2;
        position: relative;

        &::after {
          content: "";
          position: absolute;
          top: -10px;
          left: 0;
          width: 100%;
          height: 100%;
          border-radius: 2px;
          background-color: $white;
          transition: transform 0.5s ease;
        }

        &::before {
          content: "";
          position: absolute;
          left: 0;
          top: 10px;
          width: 100%;
          height: 100%;
          border-radius: 3px;
          background-color:$white;
          transition: transform 0.5s ease;
        }

        &--home {
          width: 35px;
          height: 3px;
          border-radius: 3px;
          background-color: $black;
          z-index: 2;
          position: relative;

          &::after {
            content: "";
            position: absolute;
            top: -10px;
            left: 0;
            width: 100%;
            height: 100%;
            border-radius: 3px;
            background-color: $black;
            transition: transform 0.5s ease;
          }

          &::before {
            content: "";
            position: absolute;
            left: 0;
            top: 10px;
            width: 100%;
            height: 100%;
            border-radius: 2px;
            background-color: $black;
            transition: transform 0.5s ease;
          }

          &.opened {
            &::after {
              background-color: $white;
            }

            &::before {
              background-color: $white;
            }
          }
        }

        &.opened {
          background: none;

          &::after {
            transform: translateY(10px) rotate(45deg) ;
            transition: transform 0.5s ease;
          }

          &::before {
            transform: translateY(-10px) rotate(-45deg) ;
            transition: transform 0.5s ease;
          }
        }
      }
    }
  }
}

@media screen and (min-width: 760px) {
  .lto__header {
    nav {
      .lto__menu {
        display: flex;
        justify-content: flex-end;
        align-items: center;

        li {
          list-style: none;
          margin: 0 25px;

          a {
            color: $white;
            font-weight: bold;
            text-decoration: none;
            font-size: 20px;

            &:visited {
              color: $white;
            }
          }

          &:last-child {
            margin-right: 0;
          }
        }
      }

      &.lto__nav--mobile {
        display: none;
      }

      .lto__mobile__menu {
        display: none;
      }
    }
  }
}
