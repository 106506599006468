@import './fonts.scss';
@import './default.scss';

// COLORS
$white: #FFFFFF;
$background-color-lighter: #252425;
$background-color-darker: #0d0c0c;
$black: #000000;
$black1: #222222;
$blue: #6fdbfd;
$blue1: #5ed7fd;
$blue2: #5dd5fa;
$blue3: #5dd6fc;
$grey2: #242324;
$grey3: #1b1a1a;
$grey4: #202020;
$grey5: #e5e5e5;
$purple1: #bb68fc;
$purple2: #b556ff;
$red1: #e9443d;
$red2: #ec3830;
$red3: #e9443d;
$red4: #e9443d;

// FONT
$Gotham: "Gotham";
