@import 'variables';
@import 'menu';

body {
  margin: 0;
  padding: 0;
  box-sizing: border-box;
  background: linear-gradient(180deg, rgba($background-color-lighter,1) 0%, rgba($background-color-darker,1) 100%);
}

header {
  position: fixed !important;
  height: 15vh;
  width: 100%;
  z-index: 99999;

  nav {
    height: 100%;

    .logoContainer {
      display: none !important;
    }
  }
}

main {
  height: 50vh;
  width: 100%;
  background-image: url('../assets/img/home/homebackground.jpg');
  background-position: center;
  background-size: cover;
  background-repeat: no-repeat;
  filter: grayscale(100%) contrast(120%);
  position: relative;
  display: flex;
  justify-content: flex-end;
  align-items: flex-start;

  &.coach, &.apropos {
    background-image: unset;

    &::after {
      display: none;
    }
  }

  &::after {
    content: '';
    position: absolute;
    width: 100%;
    height: 100%;
    background: linear-gradient(90deg, rgba(0, 0, 0, 0.2) 70%, rgba(255, 255, 255, .5) 100%);
  }

  .home_tagline_container {
    width: 100%;
    height: 100%;
    margin: 0;
    padding: 30px 38px;
    z-index: 1;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;


    img.home_lto_body_img {
      width: 80%;
      max-width: 300px;
      height: auto;
    }

    .home_tagline {
      display: none;
      font-family: $Gotham;
      font-weight: bold;
      font-size: 16px;
    }
  }

}

footer {
  height: 50vh;
  width: 100%;
  display: flex;
  flex-direction: column;

  .home_menu_mobile {
    display: flex;
    flex-direction: column;
    flex: 1;

    .home_pageSection {
      width: 100%;
      flex:  1 1 25%;
      // height: 60px;
      display: flex;
      justify-content: flex-start;
      align-items: center;
      position: relative;

      img {
        height: 33px;
        margin-left: 15px;

        &.home_lto_body_img--trainers {
          height: 18px;
        }
      }

      &::before {
        content: '';
        position: absolute;
        z-index: -1;
        width: 100%;
        height: 100%;
        background: linear-gradient(#202020, #0d0d0d);
      }

      &.home_outdoor {
        flex: 1 1 auto;
        background-image: url('../assets/img/home/HOME_OUTDOOR_PATTERN.png');
        background-repeat: no-repeat;
        background-position: calc(100% + 80px) center;
        background-size: auto 20px;
      }
      &.home_private {
        flex: 1 1 auto;
        background-image: url('../assets/img/home/HOME_PRIVATE_PATTERN.png');
        background-repeat: no-repeat;
        background-position: calc(100% + 118px) center;
        background-size: auto 20px;
      }
      &.home_corpo {
        flex: 1 1 auto;
        background-image: url('../assets/img/home/HOME_CORPORATE_PATTERN.png');
        background-repeat: no-repeat;
        background-position: calc(100% + 151px) center;
        background-size: auto 20px;
      }

      &.home_coach {
        flex: 1 1 auto;
      }
    }
  }

  .home_menu {
    display: none;
  }
}

@media screen and (min-width: 760px) {
  body {
    margin: 0;
    padding: 0;
    box-sizing: border-box;
    background: linear-gradient(180deg, rgba($background-color-lighter,1) 0%, rgba($background-color-darker,1) 100%);
  }
  header {
    position: relative !important;
    height: 15vh !important;
    width: 100%;

    nav {
      height: 100% !important;

      .logoContainer {
        height: 100%;
        display: flex !important;
        margin-left: 50px;
        justify-content: flex-start;
        align-items: center;
      }
    }
  }

  main {
    height: 70vh;
    width: 92%;
    background-image: url('../assets/img/home/homebackground.jpg');
    background-position: left 70%;
    background-size: cover;
    filter: grayscale(100%) contrast(120%);
    position: relative;
    display: flex;
    justify-content: flex-end;
    align-items: flex-start;

    &.coach {
      background-image: unset;

      &::after {
        display: none;
      }
    }

    &::after {
      content: '';
      position: absolute;
      width: 100%;
      height: 100%;
      background: linear-gradient(90deg, rgba(0, 0, 0, 0.2) 70%, rgba(255, 255, 255, .5) 100%);
    }

    .home_tagline_container {
      width: 25%;
      height: auto;
      padding: 0;
      margin: 30px 45px 0 0;
      z-index: 1;
      display: flex;
      flex-direction: column;
      justify-content: center;
      align-items: flex-start;

      img.home_lto_body_img {
        width: 80%;
        max-width: 300px;
        height: auto;
      }

      .home_tagline {
        margin-top: 25px;
        display: block;
      }
    }
  }

  footer {
    height: 15vh;
    width: 92%;
    display: flex;
    flex-direction: row;

    .home_menu_mobile {
      display: none;
      height: 0;
    }

    .home_menu {
      display: block;
      display: flex;
      flex: 1;

      .home_pageSection {
        flex: 1 1 auto;
        height: auto;

        &.home_outdoor {
          flex: 1 1 auto;
          background-image: url('../assets/img/home/HOME_OUTDOOR.png');
          background-repeat: no-repeat;
          background-position: bottom center;
          background-size: auto 45%;

          &:hover {
            background-image: url('../assets/img/home/hovered_images/outdoor/HOME_OUTDOOR.png');
          }
        }
        &.home_private {
          background-image: url('../assets/img/home/HOME_PRIVATE.png');
          background-repeat: no-repeat;
          background-position: bottom center;
          background-size: auto 45%;

          &:hover {
            background-image: url('../assets/img/home/hovered_images/private/HOME_PRIVATE.png');
          }
        }
        &.home_corpo {
          background-image: url('../assets/img/home/HOME_CORPORATE.png');
          background-repeat: no-repeat;
          background-position: bottom center;
          background-size: auto 45%;

          &:hover {
            background-image: url('../assets/img/home/hovered_images/corp/HOME_CORPORATE.png');
          }
        }
      }
    }
  }
}

@media screen and (min-width: 1280px) {
  main {
    .home_tagline_container {
      width: 30%;

      img.home_lto_body_img {
        width: 100%;
        max-width: unset;
      }

      .home_tagline {
        font-size: 20px;
      }
    }
  }

  footer {
    .home_menu {
      .home_pageSection {
        &.home_outdoor {
          background-size: auto 60%;
        }
        &.home_private {
          background-size: auto 60%;
        }
        &.home_corpo {
          background-size: auto 60%;
        }
      }
    }
  }
}
